import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { auth } from './utils/firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Employees from './components/Employees';
import Locations from './components/Locations';
import Sandbox from './components/Sandbox';
import { DataProvider } from './components/DataContext';
import Frame from './components/Frame';

function App() {
  const [user] = useAuthState(auth);

  return (
    <DataProvider>
      <Router>
        <Routes>
          <Route path="/" element={user ? <Navigate to="/dashboard" /> : <Login />} />

          <Route
            path="/dashboard"
            element={user ? <Frame><Dashboard /></Frame> : <Navigate to="/" />}
          />
          <Route
            path="/employees"
            element={user ? <Frame><Employees /></Frame> : <Navigate to="/" />}
          />

          <Route
            path="/locations"
            element={user ? <Frame><Locations /></Frame> : <Navigate to="/" />}
          />

          <Route
            path="/sandbox"
            element={user ? <Frame><Sandbox /></Frame> : <Navigate to="/" />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </DataProvider>
  );
}

export default App;
