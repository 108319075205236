import React, { useContext } from 'react';
import '../styles/Locations.css';
import { DataContext } from '../components/DataContext';

// Column-specific formatting function
const formatCellValue = (value, formatType) => {
    // Handle Firestore Timestamp objects
    if (value && typeof value === 'object' && value.seconds && value.nanoseconds) {
        return new Date(value.seconds * 1000).toLocaleString(); // Convert Firestore Timestamp to readable date
    }

    // Only apply formatting to numerical values, skip strings like Employee Name
    if (typeof value === 'number') {
        if (isNaN(value)) return '0'; // Handle NaN values as 0

        switch (formatType) {
            case 'currency':
                return `$${parseFloat(value).toFixed(2)}`; // Currency formatting
            case 'float':
                return parseFloat(value).toFixed(2); // Truncate to 2 decimal places
            case 'int':
                return parseInt(value, 10); // Convert to integer
            case 'percentage':
                return `${(parseFloat(value) * 100).toFixed(2)}%`; // Convert to percentage
            default:
                return value; // Return the value as is
        }
    }

    // If it's a string or non-numerical value, return it as is
    return value;
};

const LocationsDashboard = () => {
    const { locationKpis, loadingLocation } = useContext(DataContext);

    if (loadingLocation || !locationKpis || locationKpis.length === 0) {
        return <p>Loading Location Data...</p>;
    }

    // Separate "Tucson" totals from the rest
    const totalsLocation = locationKpis.find(location => location.Location === 'Tucson');
    const otherLocations = locationKpis.filter(location => location.Location !== 'Tucson');

    // Get leaderboard information based on Gross Profit
    const sortedLocations = [...otherLocations].sort((a, b) => b['gross_profit'] - a['gross_profit']);
    const firstPlace = sortedLocations[0]?.Location;
    const secondPlace = sortedLocations[1]?.Location;
    const thirdPlace = sortedLocations[2]?.Location;

    return (
        <div className="locations-dashboard">
            <div className="top-container">
                {/* Totals Location and Leaderboard */}
                <div className="location-box large">
                    <h2>{totalsLocation.Location}</h2>
                    <div className="data-group">
                        <div className="data-label">Gross Profit:</div>
                        <div className="data-value profit">{formatCellValue(totalsLocation['gross_profit'], 'currency')}</div>
                        <div className="data-label">Net Sales:</div>
                        <div className="data-value">{formatCellValue(totalsLocation['net_sales'], 'currency')}</div>
                        <div className="data-label">Cost of Goods:</div>
                        <div className="data-value expense">{formatCellValue(totalsLocation['cost_of_goods'], 'currency')}</div>
                        <div className="data-label">% to Goal:</div>
                        <div className="data-value">{formatCellValue(totalsLocation['percent_to_goal'], 'percentage')}</div>
                    </div>
                </div>

                <div className="legend-leaderboard">
                    <div className="legend-title">Today's Overview</div>
                    <p><span className="data-label">Today's Date:</span> {new Date().toLocaleDateString()}</p>
                    <p><span className="data-label">% to Month End:</span> {formatCellValue(totalsLocation['percent_to_month_end'], 'percentage')}</p>
                    <div className="leaderboard-list">
                        <div className="legend-title">Leaderboard</div>
                        <p className="leaderboard-item first">1st: {firstPlace}</p>
                        <p className="leaderboard-item second">2nd: {secondPlace}</p>
                        <p className="leaderboard-item third">3rd: {thirdPlace}</p>
                    </div>
                </div>
            </div>

            <div className="bottom-container">
                <div className="vertical-boxes-container">
                    {/* Vertical boxes for other locations */}
                    {otherLocations.map((location, index) => (
                        <div key={index} className="location-box vertical">
                            <h3>{location.Location}</h3>
                            <p className="data-label">Gross Profit: {formatCellValue(location['gross_profit'], 'currency')}</p>
                            <p className="data-label">Net Sales: {formatCellValue(location['net_sales'], 'currency')}</p>
                            <p className="data-label">Cost of Goods: {formatCellValue(location['cost_of_goods'], 'currency')}</p>
                            <p className="data-label">% to Goal: {formatCellValue(location['percent_to_goal'], 'percentage')}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LocationsDashboard;