import React, { useState } from "react";
import axios from "axios";
import '../styles/Sandbox.css';

const Sandbox = () => {
    const [terminalInput, setTerminalInput] = useState("");
    const [terminalOutput, setTerminalOutput] = useState([]);

    const handleCommand = async () => {
        if (!terminalInput.trim()) return;

        // Append user input to output
        setTerminalOutput((prev) => [...prev, { type: "user", message: terminalInput }]);

        try {
            const response = await axios.post("/sandbox", { query: terminalInput });
            const { command, result } = response.data;

            // Append AI response to output
            setTerminalOutput((prev) => [
                ...prev,
                { type: "system", message: `Command: ${command}` },
                { type: "system", message: `Result: ${JSON.stringify(result, null, 2)}` }
            ]);
        } catch (error) {
            setTerminalOutput((prev) => [
                ...prev,
                { type: "error", message: "Error processing your request." }
            ]);
        }

        setTerminalInput("");
    };

    return (
        <div className="sandbox-container">
            <div className="sandbox-header">Sandbox Terminal</div>
            <div className="sandbox-terminal">
                <div className="sandbox-output">
                    {terminalOutput.map((entry, index) => (
                        <div
                            key={index}
                            className={`sandbox-message ${entry.type === "user"
                                ? "sandbox-user"
                                : entry.type === "error"
                                    ? "sandbox-error"
                                    : "sandbox-system"
                                }`}
                        >
                            {entry.message}
                        </div>
                    ))}
                </div>
                <div className="sandbox-input-container">
                    <input
                        type="text"
                        value={terminalInput}
                        onChange={(e) => setTerminalInput(e.target.value)}
                        className="sandbox-input"
                        onKeyPress={(e) => e.key === "Enter" && handleCommand()}
                    />
                    <button onClick={handleCommand} className="sandbox-send-btn">
                        ➤
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Sandbox;
